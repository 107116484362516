:root {
  --bs-dark: #212529;
  --bs-gray-shade: #f5f6f8;
  --bs-dark-blue: #0a2b3c;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-transparent-black: #00000024;
  --bs-light-gray: #d0ced4;
  --bs-semi-transparent-black: #3d3d3d0a;
  --bs-semi-transparent-gray: #a9a9a969;
  --bs-semi-transparent-orange: #f8bc076b;
  --bs-lime-green: #4CAF50;
  --bs-midnight-blue: rgb(11, 11, 85);
  --bs-amber: #ffc107;
  --bs-gray-with-opacity: #82828263;
  --bs-black-with-opacity: rgba(0, 0, 0, 0.6);
  --bs-border-light-gray: #ccc;
  --bs-light-blue: #007bff;
  --bs-dark-gray: #2b2b2b;
  --bs-semi-transparent-light-gray: #d3d3d378;

}
